.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.root > * {
    margin-bottom: 4rem;
}

.header {
    background: #35c6fe;
    height: 4rem;
    width: 100%;
}

.content {
    max-width: 56rem;
    text-align: center;
}

.content > * {
    margin-bottom: 4rem;
}

.textLarge {
    font-family: 'Lato';
    font-size: 4rem;
    font-weight: 700;
    color: #444444;
}

.textSmall {
    font-family: 'Lato';
    font-size: 1.5rem;
    color: #a0a0a0;
}

.content > a,
.content > a:link,
.content > a:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Lato';
    font-size: 1.5rem;
    color: #3ecdfe;
    text-decoration: none;
    cursor: pointer;
}
