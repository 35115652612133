.menu {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    color: #fff;
    height: 100%;
    font-family: 'Lato';
    font-size: 16px;
}

.menuItem {
    display: inline;
    white-space: nowrap;
    text-align: center;
}

.navItem {
    display: flex;
    width: auto;
}

.user {
    height: 34px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #2da8d9;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    cursor: pointer;
}

.user img {
    height: 18px;
    margin-right: 5px;
    float: left;
}

.logout {
    height: 34px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #2da8d9;
    text-align: center;
    padding-left: 20px;
    padding-top: 13px;
    cursor: pointer;
}
